
import mixins from '@/utils/mixins';
import app, { AppLink } from '@/mixins/app';

import { authProvider } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib';
import { Group } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib/types/model/userData';

export default mixins(app).extend({
  metaInfo: {
    title: 'Overview'
  },

  computed: {
    authProvider(): any {
      return authProvider(this.$store);
    },
    computedLinks(): AppLink[] {
      const userGroups: Group[] = this.authProvider.getCurrentUser.groups ?? [];
      const isUserSuperuser: boolean =
        this.authProvider.getCurrentUser.is_superuser ?? false;
      return this.links.filter((link) => {
        const isRestrictedLink = (link.roles && link.roles?.length) ?? 0 > 0;
        const isSuperuserLink = link.superuser ?? false;

        const userHasRole = isRestrictedLink
          ? userGroups.some(
              (group) => link.roles && link.roles?.includes(group.name!)
            )
          : true;

        if (isSuperuserLink) {
          return !link.divider && isUserSuperuser;
        }
        return !link.divider && (userHasRole || this.authProvider.isUserAdmin);
      });
    }
  }
});
